import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { Color, spacing } from '@/global/style/variables';

export enum ToggleInfoHoverVariant {
  MineShaft = 'MineShaft',
  Hallon = 'Hallon',
}

export const InnerBody = styled.div`
  user-select: text;
  padding: ${spacing.x15} ${spacing.x2} ${spacing.x3} 0;
  overflow: hidden;
`;

export const IconWrapper = styled(motion.div)`
  align-self: flex-start;
  margin-left: ${spacing.x05};
`;

export const MotionWrapper = styled.div`
  overflow: hidden;
`;

export const Container = styled.div`
  user-select: none;
  width: 100%;
  text-align: left;
`;

const HoverVariant = {
  [ToggleInfoHoverVariant.MineShaft]: css`
    &:hover {
      background-color: ${Color.MineShaftHover};
    }
    &:active {
      background-color: ${Color.MineShaftActive};
    }
  `,
  [ToggleInfoHoverVariant.Hallon]: css`
    &:hover {
      background-color: ${Color.Hallon6Hover};
    }
    &:active {
      background-color: ${Color.Hallon6Active};
    }
  `,
};

export const TextAndIconWrapper = styled.button<{
  $disabled?: boolean;
  $expanded?: boolean;
  $withBackround: boolean;
  $hoverVariant: ToggleInfoHoverVariant;
}>`
  color: ${Color.MineShaft};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
  padding: ${spacing.x2} 0;

  ${({ $withBackround, $hoverVariant }) => {
    if ($withBackround) {
      return HoverVariant[$hoverVariant];
    }
  }}

  border: 1px solid transparent;
  &:focus-visible {
    border: 1px solid ${Color.Ocean};
    outline: none;
  }
`;

export const HeaderContainer = styled.div<{ $disabled: boolean }>`
  color: ${({ $disabled }) => ($disabled ? Color.Gray2 : Color.MineShaft)};

  // if header is a passed in component, e.g Typography
  > * {
    color: ${({ $disabled }) => ($disabled ? Color.Gray2 : Color.MineShaft)};
  }
`;
